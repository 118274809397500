@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  overflow-x: hidden;
}
.App {
  text-align: center;
  background-color: black;
  font-family: 'Share Tech Mono', monospace;

}
.App h1{
  margin: 0;
  padding-top: 2%;
  margin-top: 7%;
}
@media(max-width: 420px){
*{
  overflow-x: hidden;
}
}



.Navbar{
    display: flex;
    justify-content: space-between;
    background-color: gray;
    height: 5em;
    box-shadow: 0px 2px 4px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
    color: white;
    border-radius: 5px;
}
.Navbar .profPic{
    border-radius: 50%;
    width: 16em;
    height: 11em;
    margin-left: 20%;
    display: none;
    margin-top: 5%;
    border-radius: 0px 0px 30px 0px;
}
.Navbar ul{
    margin-left: 50em;
    list-style-type: none;
    display: flex;
    height: 5em;
}
.navigation{
  display: flex;
}
.navigation li{
  margin: 1em;
}
.navigation h2{
  font-size: 2rem;
  margin: .5em;
}
  .Navbar li:hover{
    cursor: default;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-animation: changeColor 9s infinite;
            animation: changeColor 9s infinite;
    font-weight: bolder;
  }
  @-webkit-keyframes changeColor{
    0%{
        color: #065143;
    }
    25%{
        color: #129490;
    }
    50%{
        color: #70b77e;
    }
    75%{
        color: #e0a890;
    }
    100%{
        color: #ce1483;
    }
}
  @keyframes changeColor{
    0%{
        color: #065143;
    }
    25%{
        color: #129490;
    }
    50%{
        color: #70b77e;
    }
    75%{
        color: #e0a890;
    }
    100%{
        color: #ce1483;
    }
}
@media(max-width: 420px){

  .Navbar{
    position: relative;
    flex-direction: column;
    height: 50%;

  }
  .Navbar ul{
    flex-direction: column;
    margin-left: 0;
    margin-right: 3em;
    height: 10em;
    
  }
  .Navbar li{
    width: 15em;
    
  }
  .navigation{
    flex-direction: column;
    margin: auto;
  }
  .flip-card{
    display: none;
  }
  .Navbar .profPic{
    display: block;
  }
}







h4{
    padding: 2em;
    font-size: 1.5rem;
    color: white;
    text-align: left;
    border-radius: 5px;
    margin: 0;
    
}
.object{
    border: 2px solid white;
    background: black;
    padding: 1%;
    margin-top: 2em;
}

h4 .insideClass{
    margin-left: 5%;
    width: 35em;
    line-height: 1.5;
}
b{
    color: rgb(155,166,191);
}
.ranFunction{
    padding: 1%;
    color: white;
    background:black;
    border: 2px solid white;
    line-height: 1.5;
    
}
.symb{
    -webkit-animation: displaySymbol 1s infinite;
            animation: displaySymbol 1s infinite;
}
@-webkit-keyframes displaySymbol {
    0%{
        color: white;
    }
    100%{
        color: rgb(155,166,191);
    }
}
@keyframes displaySymbol {
    0%{
        color: white;
    }
    100%{
        color: rgb(155,166,191);
    }
}
@media(max-width: 420px){
    .object{
        width: 22em;
        line-height: 1.5;
    }
    h4{
        margin-top: 0;
        font-size: .25rem;
        text-align: left;
    }
    h4 .insideClass{
        width: 18em;
    }
    h4 .functionName{
        text-align: left;
        
    }
    .ranFunction{
        width: 20em;
        font-size: 1rem;
        margin: auto;
    }
    .object{
        display: none;
    }
}

.projects{
    display: flex;
    flex-direction: column;
    flex-wrap: flex;
}
.projects img {
    width: 60em;
    border-radius: 5px;
    margin: 1em;
    box-shadow: 0px 3px 15px #888888;
}
.projectContainer{
    display: flex;
    margin: 1em;
    padding: 0;
}
.projectContainer h3{
    background-color: black;
    color: lightseagreen;
    text-align: left;
    padding: 1%;
    border-radius: 5px;
    border: 2px solid black;
    overflow: hidden;
    width: 100%;
    margin: auto;
    border: 2px solid white;
    box-shadow: 3px 5px #888888;
    background: white;

}
.projects .projectGit{
    width: 2em;
    border: solid white 2px;
    padding: 1em;
    background: white;
    

}
.projects .compIcon{
    width: 2em;
    border: solid white 2px;
    padding: 1em;
    background: white;
}
.projBottom{
    padding-top: 10em;
    
}
@media(max-width:420px){
    .projectContainer{
        flex-direction: column;
    }
    .projectContainer h3{
        width: 50%;
    }
    .projBottom{
        margin: 0;
        padding: 0
    }
    .projects img{
        width: 22em;
    }
    .projectContainer h3{
        font-size: 1rem;
        width: 20em;
    }
}

a{
    text-decoration: none;
    color: white;
}
.site-links{
    background: rgba(0,0,0,0.25);
    width: 85em;
    height: 15em;
    padding-bottom: 4em;
    border-radius: 5px;
    border: solid white 2px;
    box-shadow: 0px 3px 6px;
    margin: auto;
    display: none;
}
.site-links .image{
    margin-top: 10%;
    width: 4em;
    margin-left: 6%;
}
.upArrow{
    position: fixed;
    width: 3em;
    right: 0;
    bottom: 0;
}
u h4{
    /* margin-top: 5%; */
    font-size: 2.5rem;
    margin: 0;
    
}
@media (max-width: 420px){
    .upArrow{
        display: none;
    }
    u h4{
        font-size: 2rem;
    }
    
}

.skills{
    padding-left: 3em;
    margin-top: 5rem;
    width: 100%;
    height: 12em;
    display: flex;
    
}.skills .images{
    display: flex;
    flex-wrap: wrap;
    width: 65%;
    height: 10em;
    margin-left: 2em;
    background: white;
    padding: 2%;
    border: solid white 2px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px;
}
.skills .images img{
    height: 5em;
    width: 5em;
    margin: auto;
}
.homePic{
    height: 115%;
    border-radius: 5px;
    border: solid white 2px;
    box-shadow: 0px 3px 6px;
}
.imageOne{
    -webkit-animation: upDown 1.5s infinite;
            animation: upDown 1.5s infinite; 
    -webkit-transition: ease-in-out; 
    transition: ease-in-out;  
}
.imageThree{
    -webkit-animation: upDown 3.5s infinite;
            animation: upDown 3.5s infinite; 
    -webkit-transition: ease-in-out; 
    transition: ease-in-out;  
}
.imageFour{
    -webkit-animation: spin 2s infinite;
            animation: spin 2s infinite;
}
.imageFive{
    -webkit-animation: upDown 4.5s infinite;
            animation: upDown 4.5s infinite; 
    -webkit-transition: ease-in-out; 
    transition: ease-in-out;  
}
.imageSeven{
    -webkit-animation: upDown 2.5s infinite;
            animation: upDown 2.5s infinite; 
    -webkit-transition: ease-in-out; 
    transition: ease-in-out;  
}
@-webkit-keyframes upDown {
    50%{
        -webkit-transform: rotateZ(10deg);
                transform: rotateZ(10deg);
    }
}
@keyframes upDown {
    50%{
        -webkit-transform: rotateZ(10deg);
                transform: rotateZ(10deg);
    }
}
@-webkit-keyframes spin {
    100%{
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg)
    }
}
@keyframes spin {
    100%{
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg)
    }
}

@media(max-width: 420px){
    .homePic{
        display: none;
    }
    .skills{
        display: none;
    }
}
