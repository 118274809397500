.projects{
    display: flex;
    flex-direction: column;
    flex-wrap: flex;
}
.projects img {
    width: 60em;
    border-radius: 5px;
    margin: 1em;
    box-shadow: 0px 3px 15px #888888;
}
.projectContainer{
    display: flex;
    margin: 1em;
    padding: 0;
}
.projectContainer h3{
    background-color: black;
    color: lightseagreen;
    text-align: left;
    padding: 1%;
    border-radius: 5px;
    border: 2px solid black;
    overflow: hidden;
    width: 100%;
    margin: auto;
    border: 2px solid white;
    box-shadow: 3px 5px #888888;
    background: white;

}
.projects .projectGit{
    width: 2em;
    border: solid white 2px;
    padding: 1em;
    background: white;
    

}
.projects .compIcon{
    width: 2em;
    border: solid white 2px;
    padding: 1em;
    background: white;
}
.projBottom{
    padding-top: 10em;
    
}
@media(max-width:420px){
    .projectContainer{
        flex-direction: column;
    }
    .projectContainer h3{
        width: 50%;
    }
    .projBottom{
        margin: 0;
        padding: 0
    }
    .projects img{
        width: 22em;
    }
    .projectContainer h3{
        font-size: 1rem;
        width: 20em;
    }
}