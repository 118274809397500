
h4{
    padding: 2em;
    font-size: 1.5rem;
    color: white;
    text-align: left;
    border-radius: 5px;
    margin: 0;
    
}
.object{
    border: 2px solid white;
    background: black;
    padding: 1%;
    margin-top: 2em;
}

h4 .insideClass{
    margin-left: 5%;
    width: 35em;
    line-height: 1.5;
}
b{
    color: rgb(155,166,191);
}
.ranFunction{
    padding: 1%;
    color: white;
    background:black;
    border: 2px solid white;
    line-height: 1.5;
    
}
.symb{
    animation: displaySymbol 1s infinite;
}
@keyframes displaySymbol {
    0%{
        color: white;
    }
    100%{
        color: rgb(155,166,191);
    }
}
@media(max-width: 420px){
    .object{
        width: 22em;
        line-height: 1.5;
    }
    h4{
        margin-top: 0;
        font-size: .25rem;
        text-align: left;
    }
    h4 .insideClass{
        width: 18em;
    }
    h4 .functionName{
        text-align: left;
        
    }
    .ranFunction{
        width: 20em;
        font-size: 1rem;
        margin: auto;
    }
    .object{
        display: none;
    }
}
