.skills{
    padding-left: 3em;
    margin-top: 5rem;
    width: 100%;
    height: 12em;
    display: flex;
    
}.skills .images{
    display: flex;
    flex-wrap: wrap;
    width: 65%;
    height: 10em;
    margin-left: 2em;
    background: white;
    padding: 2%;
    border: solid white 2px;
    border-radius: 5px;
    box-shadow: 0px 3px 6px;
}
.skills .images img{
    height: 5em;
    width: 5em;
    margin: auto;
}
.homePic{
    height: 115%;
    border-radius: 5px;
    border: solid white 2px;
    box-shadow: 0px 3px 6px;
}
.imageOne{
    animation: upDown 1.5s infinite; 
    transition: ease-in-out;  
}
.imageThree{
    animation: upDown 3.5s infinite; 
    transition: ease-in-out;  
}
.imageFour{
    animation: spin 2s infinite;
}
.imageFive{
    animation: upDown 4.5s infinite; 
    transition: ease-in-out;  
}
.imageSeven{
    animation: upDown 2.5s infinite; 
    transition: ease-in-out;  
}
@keyframes upDown {
    50%{
        transform: rotateZ(10deg);
    }
}
@keyframes spin {
    100%{
        transform: rotateZ(360deg)
    }
}

@media(max-width: 420px){
    .homePic{
        display: none;
    }
    .skills{
        display: none;
    }
}