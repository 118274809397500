
a{
    text-decoration: none;
    color: white;
}
.site-links{
    background: rgba(0,0,0,0.25);
    width: 85em;
    height: 15em;
    padding-bottom: 4em;
    border-radius: 5px;
    border: solid white 2px;
    box-shadow: 0px 3px 6px;
    margin: auto;
    display: none;
}
.site-links .image{
    margin-top: 10%;
    width: 4em;
    margin-left: 6%;
}
.upArrow{
    position: fixed;
    width: 3em;
    right: 0;
    bottom: 0;
}
u h4{
    /* margin-top: 5%; */
    font-size: 2.5rem;
    margin: 0;
    
}
@media (max-width: 420px){
    .upArrow{
        display: none;
    }
    u h4{
        font-size: 2rem;
    }
    
}
