.Navbar{
    display: flex;
    justify-content: space-between;
    background-color: gray;
    height: 5em;
    box-shadow: 0px 2px 4px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;
    color: white;
    border-radius: 5px;
}
.Navbar .profPic{
    border-radius: 50%;
    width: 16em;
    height: 11em;
    margin-left: 20%;
    display: none;
    margin-top: 5%;
    border-radius: 0px 0px 30px 0px;
}
.Navbar ul{
    margin-left: 50em;
    list-style-type: none;
    display: flex;
    height: 5em;
}
.navigation{
  display: flex;
}
.navigation li{
  margin: 1em;
}
.navigation h2{
  font-size: 2rem;
  margin: .5em;
}
  .Navbar li:hover{
    cursor: default;
    transform: scale(1.1);
    animation: changeColor 9s infinite;
    font-weight: bolder;
  }
  @keyframes changeColor{
    0%{
        color: #065143;
    }
    25%{
        color: #129490;
    }
    50%{
        color: #70b77e;
    }
    75%{
        color: #e0a890;
    }
    100%{
        color: #ce1483;
    }
}
@media(max-width: 420px){

  .Navbar{
    position: relative;
    flex-direction: column;
    height: 50%;

  }
  .Navbar ul{
    flex-direction: column;
    margin-left: 0;
    margin-right: 3em;
    height: 10em;
    
  }
  .Navbar li{
    width: 15em;
    
  }
  .navigation{
    flex-direction: column;
    margin: auto;
  }
  .flip-card{
    display: none;
  }
  .Navbar .profPic{
    display: block;
  }
}





